import React from "react";

export default function CVIcon() {
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M53.9 4H18.37L16.3 7L15.87 60.07H54.2L56.86 56.35V7.25L53.9 4ZM28.17 21H24.66L21.13 18.41L24.82 14.96L23.29 11.3L24.82 8.92L27.22 8.18L29.22 9.39L30 11.86L28.58 14.27L32.72 18.6L28.17 21Z'
        fill='#78B9EB'
      />
      <path
        d='M52.5 3H20.5C19.1739 3 17.9021 3.52678 16.9645 4.46447C16.0268 5.40215 15.5 6.67392 15.5 8V31H7.5C7.23478 31 6.98043 31.1054 6.79289 31.2929C6.60536 31.4804 6.5 31.7348 6.5 32V55.5C6.50264 56.9579 7.08295 58.3553 8.11383 59.3862C9.14471 60.417 10.5421 60.9974 12 61H52.5C53.8261 61 55.0979 60.4732 56.0355 59.5355C56.9732 58.5979 57.5 57.3261 57.5 56V8C57.5 6.67392 56.9732 5.40215 56.0355 4.46447C55.0979 3.52678 53.8261 3 52.5 3V3ZM8.5 55.5V33H15.5V55.5C15.5 56.4283 15.1313 57.3185 14.4749 57.9749C13.8185 58.6312 12.9283 59 12 59C11.0717 59 10.1815 58.6312 9.52513 57.9749C8.86875 57.3185 8.5 56.4283 8.5 55.5V55.5ZM55.5 56C55.5 56.7956 55.1839 57.5587 54.6213 58.1213C54.0587 58.6839 53.2956 59 52.5 59H17.5V8C17.5 7.20435 17.8161 6.44129 18.3787 5.87868C18.9413 5.31607 19.7044 5 20.5 5H52.5C53.2956 5 54.0587 5.31607 54.6213 5.87868C55.1839 6.44129 55.5 7.20435 55.5 8V56Z'
        fill='#006DF0'
      />
      <path
        d='M26.83 22.34C28.0518 22.3392 29.2597 22.0805 30.3746 21.5807C31.4895 21.081 32.4864 20.3515 33.3 19.44C33.3716 19.3474 33.432 19.2467 33.48 19.14C33.5404 18.9411 33.5404 18.7289 33.48 18.53C33.2372 17.5481 32.7793 16.6323 32.1395 15.8489C31.4996 15.0655 30.6937 14.434 29.78 14C30.3042 13.4276 30.6505 12.715 30.7767 11.9492C30.903 11.1834 30.8037 10.3974 30.491 9.68702C30.1783 8.97665 29.6657 8.3726 29.0156 7.94851C28.3656 7.52442 27.6062 7.29861 26.83 7.29861C26.0539 7.29861 25.2945 7.52442 24.6444 7.94851C23.9944 8.3726 23.4817 8.97665 23.169 9.68702C22.8563 10.3974 22.757 11.1834 22.8833 11.9492C23.0095 12.715 23.3558 13.4276 23.88 14C22.9644 14.429 22.1556 15.0562 21.5123 15.8362C20.8689 16.6162 20.407 17.5295 20.16 18.51C20.0853 18.7305 20.0853 18.9695 20.16 19.19C20.2117 19.31 20.2865 19.4187 20.38 19.51C21.1945 20.4102 22.1903 21.1278 23.302 21.6156C24.4137 22.1034 25.6161 22.3503 26.83 22.34V22.34ZM24.83 11.26C24.83 10.8644 24.9473 10.4778 25.1671 10.1489C25.3868 9.81996 25.6992 9.56362 26.0646 9.41224C26.4301 9.26087 26.8322 9.22126 27.2202 9.29843C27.6082 9.3756 27.9645 9.56608 28.2442 9.84579C28.5239 10.1255 28.7144 10.4819 28.7916 10.8698C28.8688 11.2578 28.8291 11.6599 28.6778 12.0254C28.5264 12.3908 28.2701 12.7032 27.9412 12.9229C27.6123 13.1427 27.2256 13.26 26.83 13.26C26.5665 13.2613 26.3054 13.2106 26.0616 13.1106C25.8178 13.0107 25.5961 12.8636 25.4093 12.6778C25.2226 12.4919 25.0743 12.271 24.9732 12.0277C24.8721 11.7844 24.82 11.5235 24.82 11.26H24.83ZM26.83 15.26C27.8328 15.2626 28.8104 15.574 29.6299 16.152C30.4494 16.7299 31.071 17.5463 31.41 18.49C30.1731 19.6699 28.5294 20.3281 26.82 20.3281C25.1106 20.3281 23.4669 19.6699 22.23 18.49C22.5713 17.5465 23.1951 16.7312 24.0164 16.155C24.8378 15.5788 25.8167 15.2698 26.82 15.27L26.83 15.26Z'
        fill='#006DF0'
      />
      <path
        d='M37.54 12.26H42.68C42.9453 12.26 43.1996 12.1547 43.3871 11.9671C43.5747 11.7796 43.68 11.5252 43.68 11.26C43.68 10.9948 43.5747 10.7404 43.3871 10.5529C43.1996 10.3654 42.9453 10.26 42.68 10.26H37.54C37.2748 10.26 37.0205 10.3654 36.8329 10.5529C36.6454 10.7404 36.54 10.9948 36.54 11.26C36.54 11.5252 36.6454 11.7796 36.8329 11.9671C37.0205 12.1547 37.2748 12.26 37.54 12.26Z'
        fill='#006DF0'
      />
      <path
        d='M37.54 18H51.46C51.7253 18 51.9796 17.8946 52.1671 17.7071C52.3547 17.5196 52.46 17.2652 52.46 17C52.46 16.7348 52.3547 16.4804 52.1671 16.2929C51.9796 16.1054 51.7253 16 51.46 16H37.54C37.2748 16 37.0205 16.1054 36.8329 16.2929C36.6454 16.4804 36.54 16.7348 36.54 17C36.54 17.2652 36.6454 17.5196 36.8329 17.7071C37.0205 17.8946 37.2748 18 37.54 18Z'
        fill='#006DF0'
      />
      <path
        d='M51.6799 25.71H21.3199C21.0547 25.71 20.8004 25.8153 20.6128 26.0029C20.4253 26.1904 20.3199 26.4448 20.3199 26.71C20.3199 26.9752 20.4253 27.2296 20.6128 27.4171C20.8004 27.6046 21.0547 27.71 21.3199 27.71H51.6799C51.9452 27.71 52.1995 27.6046 52.3871 27.4171C52.5746 27.2296 52.6799 26.9752 52.6799 26.71C52.6799 26.4448 52.5746 26.1904 52.3871 26.0029C52.1995 25.8153 51.9452 25.71 51.6799 25.71Z'
        fill='#006DF0'
      />
      <path
        d='M51.6799 32.14H21.3199C21.0547 32.14 20.8004 32.2454 20.6128 32.4329C20.4253 32.6204 20.3199 32.8748 20.3199 33.14C20.3199 33.4052 20.4253 33.6596 20.6128 33.8471C20.8004 34.0347 21.0547 34.14 21.3199 34.14H51.6799C51.9452 34.14 52.1995 34.0347 52.3871 33.8471C52.5746 33.6596 52.6799 33.4052 52.6799 33.14C52.6799 32.8748 52.5746 32.6204 52.3871 32.4329C52.1995 32.2454 51.9452 32.14 51.6799 32.14Z'
        fill='#006DF0'
      />
      <path
        d='M51.6799 38.57H21.3199C21.0547 38.57 20.8004 38.6754 20.6128 38.8629C20.4253 39.0504 20.3199 39.3048 20.3199 39.57C20.3199 39.8352 20.4253 40.0896 20.6128 40.2771C20.8004 40.4647 21.0547 40.57 21.3199 40.57H51.6799C51.9452 40.57 52.1995 40.4647 52.3871 40.2771C52.5746 40.0896 52.6799 39.8352 52.6799 39.57C52.6799 39.3048 52.5746 39.0504 52.3871 38.8629C52.1995 38.6754 51.9452 38.57 51.6799 38.57Z'
        fill='#006DF0'
      />
      <path
        d='M51.6799 45H21.3199C21.0547 45 20.8004 45.1054 20.6128 45.2929C20.4253 45.4804 20.3199 45.7348 20.3199 46C20.3199 46.2652 20.4253 46.5196 20.6128 46.7071C20.8004 46.8946 21.0547 47 21.3199 47H51.6799C51.9452 47 52.1995 46.8946 52.3871 46.7071C52.5746 46.5196 52.6799 46.2652 52.6799 46C52.6799 45.7348 52.5746 45.4804 52.3871 45.2929C52.1995 45.1054 51.9452 45 51.6799 45Z'
        fill='#006DF0'
      />
      <path
        d='M51.6799 51.43H21.3199C21.0547 51.43 20.8004 51.5353 20.6128 51.7229C20.4253 51.9104 20.3199 52.1648 20.3199 52.43C20.3199 52.6952 20.4253 52.9496 20.6128 53.1371C20.8004 53.3246 21.0547 53.43 21.3199 53.43H51.6799C51.9452 53.43 52.1995 53.3246 52.3871 53.1371C52.5746 52.9496 52.6799 52.6952 52.6799 52.43C52.6799 52.1648 52.5746 51.9104 52.3871 51.7229C52.1995 51.5353 51.9452 51.43 51.6799 51.43Z'
        fill='#006DF0'
      />
    </svg>
  );
}
