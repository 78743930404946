import React from "react";

export default function EsFlagIcon() {
  return (
    <svg
      className='es-svg'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='80px'
      height='80px'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g>
        <path
          fill='#c64342'
          d='M -0.5,9.5 C 26.1667,9.5 52.8333,9.5 79.5,9.5C 79.5,14.8333 79.5,20.1667 79.5,25.5C 52.8333,25.5 26.1667,25.5 -0.5,25.5C -0.5,20.1667 -0.5,14.8333 -0.5,9.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f68f8e'
          d='M 0.5,10.5 C 26.5,10.5 52.5,10.5 78.5,10.5C 78.5,15.1667 78.5,19.8333 78.5,24.5C 52.5,24.5 26.5,24.5 0.5,24.5C 0.5,19.8333 0.5,15.1667 0.5,10.5 Z'
        />
      </g>
      <g>
        <path
          fill='#feeca1'
          d='M -0.5,25.5 C 26.1667,25.5 52.8333,25.5 79.5,25.5C 79.5,34.8333 79.5,44.1667 79.5,53.5C 52.8333,53.5 26.1667,53.5 -0.5,53.5C -0.5,44.1667 -0.5,34.8333 -0.5,25.5 Z'
        />
      </g>
      <g>
        <path
          fill='#c64342'
          d='M -0.5,53.5 C 26.1667,53.5 52.8333,53.5 79.5,53.5C 79.5,58.8333 79.5,64.1667 79.5,69.5C 52.8333,69.5 26.1667,69.5 -0.5,69.5C -0.5,64.1667 -0.5,58.8333 -0.5,53.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f68f8e'
          d='M 0.5,54.5 C 26.5,54.5 52.5,54.5 78.5,54.5C 78.5,59.1667 78.5,63.8333 78.5,68.5C 52.5,68.5 26.5,68.5 0.5,68.5C 0.5,63.8333 0.5,59.1667 0.5,54.5 Z'
        />
      </g>
    </svg>
  );
}
