import React from "react";

export default function EnFlagIcon() {
  return (
    <svg
      width='80px'
      height='80px'
      className='en-svg'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g>
        <path
          fill='#a2b6cb'
          d='M -0.5,11.5 C 2.5,11.5 5.5,11.5 8.5,11.5C 9.16667,12.1667 9.83333,12.8333 10.5,13.5C 7.2869,13.19 4.2869,13.5233 1.5,14.5C 0.833333,14.1667 0.166667,13.8333 -0.5,13.5C -0.5,12.8333 -0.5,12.1667 -0.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M 8.5,11.5 C 16.8333,11.5 25.1667,11.5 33.5,11.5C 33.5,12.1667 33.5,12.8333 33.5,13.5C 26.1667,13.5 18.8333,13.5 11.5,13.5C 11.1667,13.5 10.8333,13.5 10.5,13.5C 9.83333,12.8333 9.16667,12.1667 8.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#c64342'
          d='M 35.5,11.5 C 38.1667,11.5 40.8333,11.5 43.5,11.5C 43.5,19.5 43.5,27.5 43.5,35.5C 55.5,35.5 67.5,35.5 79.5,35.5C 79.5,38.1667 79.5,40.8333 79.5,43.5C 67.5,43.5 55.5,43.5 43.5,43.5C 43.5,51.5 43.5,59.5 43.5,67.5C 40.8333,67.5 38.1667,67.5 35.5,67.5C 35.5,59.5 35.5,51.5 35.5,43.5C 23.5,43.5 11.5,43.5 -0.5,43.5C -0.5,40.8333 -0.5,38.1667 -0.5,35.5C 11.5,35.5 23.5,35.5 35.5,35.5C 35.5,27.5 35.5,19.5 35.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M 45.5,11.5 C 54.5,11.5 63.5,11.5 72.5,11.5C 71.8333,12.1667 71.1667,12.8333 70.5,13.5C 70.1667,13.5 69.8333,13.5 69.5,13.5C 61.5,13.5 53.5,13.5 45.5,13.5C 45.5,12.8333 45.5,12.1667 45.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#a5b4c8'
          d='M 72.5,11.5 C 73.8333,11.5 75.1667,11.5 76.5,11.5C 76.0269,12.9063 75.0269,13.573 73.5,13.5C 72.5,13.5 71.5,13.5 70.5,13.5C 71.1667,12.8333 71.8333,12.1667 72.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#cb5251'
          d='M 76.5,11.5 C 77.5,11.5 78.5,11.5 79.5,11.5C 79.5,12.1667 79.5,12.8333 79.5,13.5C 78.5,13.8333 77.8333,14.5 77.5,15.5C 68.7181,21.0654 60.0515,26.8987 51.5,33C 49.8333,33.6667 48.1667,33.6667 46.5,33C 55.6814,26.6368 64.6814,20.1368 73.5,13.5C 75.0269,13.573 76.0269,12.9063 76.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f9f7f9'
          d='M 33.5,11.5 C 34.1667,11.5 34.8333,11.5 35.5,11.5C 35.5,19.5 35.5,27.5 35.5,35.5C 23.5,35.5 11.5,35.5 -0.5,35.5C -0.5,34.8333 -0.5,34.1667 -0.5,33.5C 0.166667,33.5 0.833333,33.5 1.5,33.5C 7.84209,33.6662 14.1754,33.4995 20.5,33C 14.1382,28.823 7.80485,24.6563 1.5,20.5C 0.833333,20.1667 0.166667,19.8333 -0.5,19.5C -0.5,18.8333 -0.5,18.1667 -0.5,17.5C 7.61348,22.3964 15.6135,27.563 23.5,33C 25.1667,33.6667 26.8333,33.6667 28.5,33C 19.434,26.8106 10.434,20.6439 1.5,14.5C 4.2869,13.5233 7.2869,13.19 10.5,13.5C 10.8333,13.5 11.1667,13.5 11.5,13.5C 18.3382,18.8472 25.5048,23.8472 33,28.5C 33.4993,23.5111 33.6659,18.5111 33.5,13.5C 33.5,12.8333 33.5,12.1667 33.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8cb7f0'
          d='M 11.5,13.5 C 18.8333,13.5 26.1667,13.5 33.5,13.5C 33.6659,18.5111 33.4993,23.5111 33,28.5C 25.5048,23.8472 18.3382,18.8472 11.5,13.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f68f8e'
          d='M 37.5,13.5 C 38.8333,13.5 40.1667,13.5 41.5,13.5C 41.5,21.5 41.5,29.5 41.5,37.5C 53.5,37.5 65.5,37.5 77.5,37.5C 77.5,38.8333 77.5,40.1667 77.5,41.5C 65.5,41.5 53.5,41.5 41.5,41.5C 41.5,49.5 41.5,57.5 41.5,65.5C 40.1667,65.5 38.8333,65.5 37.5,65.5C 37.5,57.5 37.5,49.5 37.5,41.5C 25.5,41.5 13.5,41.5 1.5,41.5C 1.5,40.1667 1.5,38.8333 1.5,37.5C 13.5,37.5 25.5,37.5 37.5,37.5C 37.5,29.5 37.5,21.5 37.5,13.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f9f7f8'
          d='M 43.5,11.5 C 44.1667,11.5 44.8333,11.5 45.5,11.5C 45.5,12.1667 45.5,12.8333 45.5,13.5C 45.1718,18.8746 45.5052,24.2079 46.5,29.5C 54.4076,24.3908 62.0742,19.0574 69.5,13.5C 69.8333,13.5 70.1667,13.5 70.5,13.5C 71.5,13.5 72.5,13.5 73.5,13.5C 64.6814,20.1368 55.6814,26.6368 46.5,33C 48.1667,33.6667 49.8333,33.6667 51.5,33C 60.0515,26.8987 68.7181,21.0654 77.5,15.5C 77.5,17.5 77.5,19.5 77.5,21.5C 71.8621,25.3233 66.1954,29.1566 60.5,33C 66.1569,33.4994 71.8236,33.6661 77.5,33.5C 78.1667,33.5 78.8333,33.5 79.5,33.5C 79.5,34.1667 79.5,34.8333 79.5,35.5C 67.5,35.5 55.5,35.5 43.5,35.5C 43.5,27.5 43.5,19.5 43.5,11.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8db8f0'
          d='M 45.5,13.5 C 53.5,13.5 61.5,13.5 69.5,13.5C 62.0742,19.0574 54.4076,24.3908 46.5,29.5C 45.5052,24.2079 45.1718,18.8746 45.5,13.5 Z'
        />
      </g>
      <g>
        <path
          fill='#cb5351'
          d='M -0.5,13.5 C 0.166667,13.8333 0.833333,14.1667 1.5,14.5C 10.434,20.6439 19.434,26.8106 28.5,33C 26.8333,33.6667 25.1667,33.6667 23.5,33C 15.6135,27.563 7.61348,22.3964 -0.5,17.5C -0.5,16.1667 -0.5,14.8333 -0.5,13.5 Z'
        />
      </g>
      <g>
        <path
          fill='#a5b4c8'
          d='M 79.5,13.5 C 79.5,15.8333 79.5,18.1667 79.5,20.5C 78.8333,20.8333 78.1667,21.1667 77.5,21.5C 77.5,19.5 77.5,17.5 77.5,15.5C 77.8333,14.5 78.5,13.8333 79.5,13.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M -0.5,19.5 C 0.166667,19.8333 0.833333,20.1667 1.5,20.5C 1.5,24.8333 1.5,29.1667 1.5,33.5C 0.833333,33.5 0.166667,33.5 -0.5,33.5C -0.5,28.8333 -0.5,24.1667 -0.5,19.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8cb8f0'
          d='M 1.5,20.5 C 7.80485,24.6563 14.1382,28.823 20.5,33C 14.1754,33.4995 7.84209,33.6662 1.5,33.5C 1.5,29.1667 1.5,24.8333 1.5,20.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4f7ab5'
          d='M 79.5,20.5 C 79.5,24.8333 79.5,29.1667 79.5,33.5C 78.8333,33.5 78.1667,33.5 77.5,33.5C 77.5,29.5 77.5,25.5 77.5,21.5C 78.1667,21.1667 78.8333,20.8333 79.5,20.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8eb9f0'
          d='M 77.5,21.5 C 77.5,25.5 77.5,29.5 77.5,33.5C 71.8236,33.6661 66.1569,33.4994 60.5,33C 66.1954,29.1566 71.8621,25.3233 77.5,21.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f9f6f8'
          d='M -0.5,43.5 C 11.5,43.5 23.5,43.5 35.5,43.5C 35.5,51.5 35.5,59.5 35.5,67.5C 34.8333,67.5 34.1667,67.5 33.5,67.5C 33.5,66.8333 33.5,66.1667 33.5,65.5C 33.6661,59.8236 33.4994,54.1569 33,48.5C 25.1879,54.3106 17.3545,59.9772 9.5,65.5C 9.16667,65.5 8.83333,65.5 8.5,65.5C 7.5,65.5 6.5,65.5 5.5,65.5C 14.3186,58.8632 23.3186,52.3632 32.5,46C 30.8333,45.3333 29.1667,45.3333 27.5,46C 18.9485,52.1013 10.2819,57.9346 1.5,63.5C 1.5,61.5 1.5,59.5 1.5,57.5C 1.5,57.1667 1.5,56.8333 1.5,56.5C 7.26423,53.2518 12.9309,49.7518 18.5,46C 12.8431,45.5006 7.17645,45.3339 1.5,45.5C 0.833333,45.5 0.166667,45.5 -0.5,45.5C -0.5,44.8333 -0.5,44.1667 -0.5,43.5 Z'
        />
      </g>
      <g>
        <path
          fill='#f8f7f9'
          d='M 79.5,43.5 C 79.5,44.1667 79.5,44.8333 79.5,45.5C 78.8333,45.5 78.1667,45.5 77.5,45.5C 72.4889,45.3341 67.4889,45.5007 62.5,46C 67.2185,49.688 72.2185,52.8546 77.5,55.5C 77.8417,56.3382 78.5084,56.6716 79.5,56.5C 79.5,57.5 79.5,58.5 79.5,59.5C 72.7146,55.2215 66.0479,50.7215 59.5,46C 57.8333,45.3333 56.1667,45.3333 54.5,46C 62.2337,51.5217 69.9004,57.0217 77.5,62.5C 77.5,63.5 77.5,64.5 77.5,65.5C 75.8333,65.5 74.1667,65.5 72.5,65.5C 72.1667,65.5 71.8333,65.5 71.5,65.5C 63.18,59.3631 54.68,53.3631 46,47.5C 45.5005,53.4908 45.3338,59.4908 45.5,65.5C 45.5,66.1667 45.5,66.8333 45.5,67.5C 44.8333,67.5 44.1667,67.5 43.5,67.5C 43.5,59.5 43.5,51.5 43.5,43.5C 55.5,43.5 67.5,43.5 79.5,43.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M -0.5,45.5 C 0.166667,45.5 0.833333,45.5 1.5,45.5C 1.5,49.1667 1.5,52.8333 1.5,56.5C 1.5,56.8333 1.5,57.1667 1.5,57.5C 0.833333,57.5 0.166667,57.5 -0.5,57.5C -0.5,53.5 -0.5,49.5 -0.5,45.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8db8f0'
          d='M 1.5,45.5 C 7.17645,45.3339 12.8431,45.5006 18.5,46C 12.9309,49.7518 7.26423,53.2518 1.5,56.5C 1.5,52.8333 1.5,49.1667 1.5,45.5 Z'
        />
      </g>
      <g>
        <path
          fill='#cb5150'
          d='M 5.5,65.5 C 3.97306,65.427 2.97306,66.0937 2.5,67.5C 1.5,67.5 0.5,67.5 -0.5,67.5C -0.5,66.8333 -0.5,66.1667 -0.5,65.5C 0.5,65.1667 1.16667,64.5 1.5,63.5C 10.2819,57.9346 18.9485,52.1013 27.5,46C 29.1667,45.3333 30.8333,45.3333 32.5,46C 23.3186,52.3632 14.3186,58.8632 5.5,65.5 Z'
        />
      </g>
      <g>
        <path
          fill='#cb5251'
          d='M 79.5,59.5 C 79.5,60.8333 79.5,62.1667 79.5,63.5C 78.8333,63.1667 78.1667,62.8333 77.5,62.5C 69.9004,57.0217 62.2337,51.5217 54.5,46C 56.1667,45.3333 57.8333,45.3333 59.5,46C 66.0479,50.7215 72.7146,55.2215 79.5,59.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8eb9f0'
          d='M 77.5,45.5 C 77.5,48.8333 77.5,52.1667 77.5,55.5C 72.2185,52.8546 67.2185,49.688 62.5,46C 67.4889,45.5007 72.4889,45.3341 77.5,45.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4f7bb5'
          d='M 77.5,45.5 C 78.1667,45.5 78.8333,45.5 79.5,45.5C 79.5,49.1667 79.5,52.8333 79.5,56.5C 78.5084,56.6716 77.8417,56.3382 77.5,55.5C 77.5,52.1667 77.5,48.8333 77.5,45.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8cb7f0'
          d='M 71.5,65.5 C 62.8333,65.5 54.1667,65.5 45.5,65.5C 45.3338,59.4908 45.5005,53.4908 46,47.5C 54.68,53.3631 63.18,59.3631 71.5,65.5 Z'
        />
      </g>
      <g>
        <path
          fill='#8cb7f0'
          d='M 33.5,65.5 C 25.5,65.5 17.5,65.5 9.5,65.5C 17.3545,59.9772 25.1879,54.3106 33,48.5C 33.4994,54.1569 33.6661,59.8236 33.5,65.5 Z'
        />
      </g>
      <g>
        <path
          fill='#a3b3c8'
          d='M -0.5,57.5 C 0.166667,57.5 0.833333,57.5 1.5,57.5C 1.5,59.5 1.5,61.5 1.5,63.5C 1.16667,64.5 0.5,65.1667 -0.5,65.5C -0.5,62.8333 -0.5,60.1667 -0.5,57.5 Z'
        />
      </g>
      <g>
        <path
          fill='#a3b6cc'
          d='M 77.5,62.5 C 78.1667,62.8333 78.8333,63.1667 79.5,63.5C 79.5,64.8333 79.5,66.1667 79.5,67.5C 77.8333,67.5 76.1667,67.5 74.5,67.5C 73.8333,66.8333 73.1667,66.1667 72.5,65.5C 74.1667,65.5 75.8333,65.5 77.5,65.5C 77.5,64.5 77.5,63.5 77.5,62.5 Z'
        />
      </g>
      <g>
        <path
          fill='#a4b2c8'
          d='M 5.5,65.5 C 6.5,65.5 7.5,65.5 8.5,65.5C 7.83333,66.1667 7.16667,66.8333 6.5,67.5C 5.16667,67.5 3.83333,67.5 2.5,67.5C 2.97306,66.0937 3.97306,65.427 5.5,65.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M 8.5,65.5 C 8.83333,65.5 9.16667,65.5 9.5,65.5C 17.5,65.5 25.5,65.5 33.5,65.5C 33.5,66.1667 33.5,66.8333 33.5,67.5C 24.5,67.5 15.5,67.5 6.5,67.5C 7.16667,66.8333 7.83333,66.1667 8.5,65.5 Z'
        />
      </g>
      <g>
        <path
          fill='#4e7ab5'
          d='M 45.5,65.5 C 54.1667,65.5 62.8333,65.5 71.5,65.5C 71.8333,65.5 72.1667,65.5 72.5,65.5C 73.1667,66.1667 73.8333,66.8333 74.5,67.5C 64.8333,67.5 55.1667,67.5 45.5,67.5C 45.5,66.8333 45.5,66.1667 45.5,65.5 Z'
        />
      </g>
    </svg>
  );
}
