import React from "react";

export default function PugIcon() {
  const svgStyles = {
    st0: { fill: "#c1272d" },
    st1: { fill: "#efcca3" },
    st2: { fill: "#ed1c24" },
    st3: { fill: "#ccac8d" },
    st4: { fill: "#fff" },
    st5: { fill: "#ff931e" },
    st6: { fill: "#ffb81e" },
    st7: { fill: "#56332b" },
    st8: { fill: "#442823" },
    st9: { fill: "#7f4a41" },
    st10: { fill: "#331712" },
    st11: { fill: "#fc6" },
    st12: { fill: "#ccc" },
    st13: { fill: "#b3b3b3" },
    st14: { fill: "#989898" },
    st15: { fill: "#323232" },
    st16: { fill: "#1e1e1e" },
    st17: { fill: "#4c4c4c" },
    st18: { fill: "#e6e6e6" },
    st19: { fill: "#606060" },
  };

  return (
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128'>
      <path
        style={svgStyles.st1}
        d='M107.4 50.9c-.2-4.4.4-8.3-1.6-11.6-4.8-8.2-16.8-13-40.8-13v.7h-.5.5v-.7c-24 0-36.6 4.8-41.4 13.1-1.9 3.4-1.7 7.2-2 11.6-.2 3.5-1.8 7.2-1.1 11.2.8 5.2 1.1 10.4 1.9 15.2.6 3.9 6 7.2 6.5 10.9 1.4 10.2 12 14.9 36 14.9v.8h-.6.7v-.8c24 0 34.2-4.7 35.5-14.9.5-3.8 5.5-7 6.1-10.9.8-4.8 1.1-10 1.9-15.2.7-4-.9-7.8-1.1-11.3z'
      />
      <path
        style={svgStyles.st3}
        d='M64.6 54.5c4.3.1 7.3 2.8 10.1 5.3 3.3 2.9 8.9 4.9 11.2 7.4 2.3 2.5 5.3 5 6.4 8.9 1.1 3.9 1.4 8.9 1.4 10.2 0 1.3.7 1 2.7 0 4.7-2.3 9.9-8.5 9.9-8.5-.6 3.9-5.7 7.4-6.2 11.1C98.9 99.1 89 104 64.5 104h-.1.6'
      />
      <path
        style={svgStyles.st3}
        d='M80.4 46.7c.9 3.1 4.1 13.6-2.1 10.1 0 0 2.6 1.5 4.2 7.2 1.7 5.7 5.8 6.4 5.8 6.4s6.7 1.3 11.7-3c4.2-3.6 4.9-10 3.1-14.9-1.8-4.8-5-6.3-9.7-7.3-4.7-1.1-14.1-2-13 1.5z'
      />
      <circle cx='92.3' cy='58.1' r='8.8' />
      <circle style={svgStyles.st4} cx='90' cy='54.2' r='2.3' />
      <path
        style={svgStyles.st1}
        d='M78.9 57.7s7.9 5.4 12.2 10.7c4.3 5.3 4.2 6.3 4.2 6.3l-3.1 1.4s-4.4-8.3-9.8-11.4c-5.5-3.1-6.1-5.7-6.1-5.7l2.6-1.3z'
      />
      <path
        style={svgStyles.st3}
        d='M64.9 54.5c-4.3.1-7.5 2.8-10.4 5.3-3.3 2.9-9.1 4.9-11.4 7.4-2.3 2.5-5.4 5-6.5 8.9-1.1 3.9-1.5 8.9-1.5 10.2 0 1.3.2 1.4-2.7 0-4.7-2.2-9.9-8.5-9.9-8.5.6 3.9 5.7 7.4 6.2 11.1C30.1 99.1 40 104 64.5 104h.5'
      />
      <path
        style={svgStyles.st7}
        d='M88.1 71.4C83.3 65.5 75.6 60 64.9 60h-.1c-10.7 0-18.4 5.5-23.2 11.4-5 6.1-4.6 8.5-4.6 14.3 0 21 7.4 15 12.3 17.6 5 2.5 10.2 1.7 15.5 1.7h.1c5.4 0 10.5.7 15.5-1.8 4.9-2.5 12.3 3.7 12.3-17.3.1-5.8.4-8.4-4.6-14.5z'
      />
      <path
        style={svgStyles.st8}
        d='M64.4 65.2s-.7 9.7-2.1 11.6l2.6-.6-.5-11z'
      />
      <path
        style={svgStyles.st8}
        d='M65.1 65.2s.7 9.7 2.1 11.6l-2.6-.6.5-11z'
      />
      <path
        style={svgStyles.st7}
        d='M56.7 62.9c-1-2.3 2.6-6 8.3-6.1 5.7 0 9.3 3.7 8.3 6.1-1 2.4-4.6 3.1-8.3 3.2-3.6-.1-7.3-.8-8.3-3.2z'
      />
      <path d='M65 65.2c0-.4 3.4-.5 5.2-1.7 0 0-3.7 1.2-4.5.7-.8-.4-1-1.6-1-1.6s-.3 1.2-.9 1.6c-.7.4-4.9-.7-4.9-.7s5.6 1.4 5.6 1.7c0 .3-.1 1.3-.1 2 0 2.5 0 8.7.4 9.2.6.9.4-6.7.4-9.2-.1-.8-.1-1.6-.2-2z' />
      <path
        style={svgStyles.st9}
        d='M65.2 78.6c1.7 0 4.7 1.2 7.4 3.1-2.6-2.9-5.7-4.9-7.4-4.9-1.8 0-5.6 2.2-8.3 5.4 2.8-2.2 6.4-3.6 8.3-3.6z'
      />
      <path
        style={svgStyles.st8}
        d='M64.5 96.3c-3.8 0-7.5-1.2-10.9-2.1-.7-.2-1.4.3-2.1.1-6.3-2-11.4-5.4-14.5-9.7v1c0 21 7.4 15.1 12.3 17.6 5 2.5 10.2 1.7 15.5 1.7h.1c5.4 0 10.5.7 15.5-1.8 4.9-2.5 12.3 3.6 12.3-17.4 0-.8 0-1.6.1-2.3-2.9 4.7-8.2 8.4-14.8 10.6-.6.2-2-.3-2.6-.2-3.6 1.2-6.8 2.5-10.9 2.5z'
      />
      <path
        style={svgStyles.st8}
        d='M55 85s-2.5 7.5-.8 10.8l-2.3-1s1.7-7.6 3.1-9.8zM74.8 85s2.5 7.5.8 10.8l2.3-1s-1.8-7.6-3.1-9.8z'
      />
      <path
        style={svgStyles.st3}
        d='M48.6 46.7c-.9 3.1-4.1 13.6 2.1 10.1 0 0-2.6 1.5-4.2 7.2s-5.8 6.4-5.8 6.4-6.7 1.3-11.7-3c-4.2-3.6-4.9-10-3.1-14.9s5-6.3 9.7-7.3c4.7-1.1 14-2 13 1.5z'
      />
      <path d='M64.9 76.8c2.7 0 11.1 5.8 11.2 12.9v-.4c0-7.4-6.8-13.3-11.2-13.3-4.4 0-11.2 6-11.2 13.3v.4c.1-7.1 8.5-12.9 11.2-12.9z' />
      <ellipse
        transform='rotate(-14.465 66.712 61.468)'
        style={svgStyles.st10}
        cx='66.7'
        cy='61.5'
        rx='.8'
        ry='1.5'
      />
      <ellipse
        transform='rotate(17.235 62.371 61.462)'
        style={svgStyles.st10}
        cx='62.4'
        cy='61.5'
        rx='.8'
        ry='1.5'
      />
      <circle cx='37.2' cy='58.1' r='8.8' />
      <circle style={svgStyles.st4} cx='39.5' cy='54.2' r='2.3' />
      <path
        style={svgStyles.st9}
        d='M67.5 58.2c0-.1-2.3 1-2.9 1.1-.6-.1-2.9-1.2-2.9-1.1h5.8z'
      />
      <path
        style={svgStyles.st1}
        d='M50 57.7s-7.9 5.4-12.2 10.7c-4.3 5.3-4.2 6.3-4.2 6.3l3.1 1.4s4.4-8.3 9.8-11.4 6.1-5.7 6.1-5.7L50 57.7z'
      />
      <path
        style={svgStyles.st3}
        d='M32.7 41.7S30 49.1 24 52.2c0 0 9.4-1.1 8.7-10.5zM95.8 41.7s2.7 7.4 8.7 10.5c0 0-9.4-1.1-8.7-10.5zM78.7 55.5s-5.9-6.2-13.8-6.4h.1.1c-8 .2-13.8 6.4-13.8 6.4 6.9-4.8 12.8-4.7 13.8-4.7-.1 0 6.7-.1 13.6 4.7zM71.8 42.5s-3-4.2-7-4.3h.2c-3 .1-6.9 4.3-6.9 4.3 3.4-3.3 6.9-3.2 6.9-3.2s3.3-.1 6.8 3.2zM37.2 73.2s-4.7 2.3-8.1.9H29c-3-1.7-4.5-6.8-4.5-6.8s3 9 12.7 5.9zM92 73.2s4.7 2.3 8.1.9c4-1.7 4.6-6.8 4.6-6.8s-3 9-12.7 5.9z'
      />
      <path
        style={svgStyles.st3}
        d='M42.6 41.2c2.6-.5 6.9-.6 10.3.5 4.3 1.5.8 7 1.7 7.3.9.3 2.1-3.8 10.1-3.4 8.1.4 9 4 10.1 3.4s-1.1-10 11-7.8c0 0-12.7-3.4-12.1 5.8 0 0-7.3-5.6-17.5-.6.1 0 2.7-8.6-13.6-5.2zM86.9 41.2c.2 0 .3.1.4.1.1 0-.1-.1-.4-.1zM86.9 41.2zM39.1 28.9S28.3 42.5 26.7 47.7c-1.6 5.3-2.8 27-4.2 30.1l-5-21.4 9.2-22.3 12.4-5.2zM89.9 28.9s10.8 13.6 12.4 18.8c1.6 5.3 2.8 27 4.2 30.1l5-21.4-9.2-22.3-12.4-5.2z'
      />
      <path
        style={svgStyles.st7}
        d='M89.4 28.9s11.6 9.7 15 20.9c3.4 11.2 2 24.8 4.6 26.5 3.7 2.4 7.9-11.9 9.3-13.4 2.2-2.4 9.5-8.5 10-9.6.5-1.1-14.8-17.8-21.5-21.1-8.1-3.8-18.1-4.1-17.4-3.3z'
      />
      <path
        style={svgStyles.st8}
        d='M99.3 34.9s13.7 17.5 13.5 39.3l5.5-11.2c-.1 0-4.9-14.3-19-28.1z'
      />
      <path
        style={svgStyles.st7}
        d='M39.1 28.9s-11.6 9.7-15 20.9-2 24.8-4.6 26.5c-3.7 2.4-7.9-11.9-9.3-13.4C8 60.5.7 54.4.2 53.3-.3 52.2 15 35.5 21.7 32.2c8.1-3.8 18.1-4.1 17.4-3.3z'
      />
      <path
        style={svgStyles.st8}
        d='M29.2 34.9S15.5 52.4 15.7 74.2L10.3 63s4.8-14.3 18.9-28.1z'
      />
      <path
        style={svgStyles.st3}
        d='M21.8 74.6s1 5.4 2.6 7.1.5-1.3.5-1.3-1.7-.9-1.4-7.8-1.7 2-1.7 2zM107.1 74.6s-1 5.4-2.6 7.1-.5-1.3-.5-1.3 1.7-.9 1.4-7.8 1.7 2 1.7 2z'
      />
      <g>
        <circle style={svgStyles.st8} cx='54.5' cy='70.5' r='.8' />
        <circle style={svgStyles.st8} cx='49.9' cy='75.3' r='.8' />
        <circle style={svgStyles.st8} cx='48.4' cy='70.5' r='.8' />
      </g>
      <g>
        <circle style={svgStyles.st8} cx='74' cy='70.5' r='.8' />
        <circle style={svgStyles.st8} cx='78.6' cy='75.3' r='.8' />
        <circle style={svgStyles.st8} cx='80.1' cy='70.5' r='.8' />
      </g>
    </svg>
  );
}
