import React from "react";

export default function GraphQLIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'>
      <path
        fill='#ff4081'
        d='M24.5,45.161L7,34.82V14.18L24.5,3.839L42,14.18V34.82L24.5,45.161z M9,33.68l15.5,9.159L40,33.68 V15.32L24.5,6.161L9,15.32V33.68z'
      />
      <circle cx='24.5' cy='5.5' r='3.5' fill='#ff4081' />
      <circle cx='24.5' cy='43.5' r='3.5' fill='#ff4081' />
      <circle cx='8.5' cy='33.5' r='3.5' fill='#ff4081' />
      <circle cx='40.5' cy='33.5' r='3.5' fill='#ff4081' />
      <circle cx='8.5' cy='15.5' r='3.5' fill='#ff4081' />
      <circle cx='40.5' cy='15.5' r='3.5' fill='#ff4081' />
      <path
        fill='#ff4081'
        d='M42.72,35H6.28L24.5,2.978L42.72,35z M9.72,33H39.28L24.5,7.022L9.72,33z'
      />
    </svg>
  );
}
